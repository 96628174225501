<template>
  <div>
    <div class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des cadres rattachés à la capacité SI
      </span>
      <div class="d-flex justify-content-end">
        <CustomAddButton
          text="Ajouter un cadre"
          :disabled="disabled || (!cadresTypes.length ? true : false)"
          @click="addInput"
        />
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <b-table
      :items="tableItems"
      responsive
      :fields="tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      bordered
      striped
      show-empty
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>

      <template v-slot:cell(type)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value || "-"
        }}</span>
        <div id="add-page" class="row col-12 p-0 m-0" v-else>
          <div
            class="font-weight-normal p-0 form-control"
            style="padding: 0 !important; height: fit-content"
            :class="{
              'is-invalid': $v.type.$error && $v.type.$dirty,
            }"
          >
            <v-select
              appendToBody
              style="min-width: max-content; background: transparent"
              :options="cadresTypes"
              v-model="type"
              placeholder="Type"
            />
          </div>

          <!-- ERROR MESSAGES -->
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.type.$dirty"
          >
            {{ !$v.type.required ? "Champ obligatoire" : "" }}
          </span>
          <!--  -->
        </div>
      </template>

      <template v-slot:cell(name)="data">
        <b-link
          :to="{
            name: 'show-principe-ref',
            params: {
              id: data.item.id,
              slug: data.item.slug,
            },
          }"
          v-if="!data.item.hasOwnProperty('editMode')"
          >{{ data.value }}</b-link
        >
        <div id="add-page" class="row col-12 p-0 m-0" v-else>
          <div
            class="font-weight-normal p-0 form-control"
            style="padding: 0 !important; height: fit-content"
            :class="{
              'is-invalid': $v.selectedCadre.$error && $v.selectedCadre.$dirty,
            }"
          >
            <v-select
              :options="filtredCadres"
              appendToBody
              label="text"
              v-model="selectedCadre"
              :reduce="(cadre) => cadre.value"
              style="min-width: max-content; background: transparent"
              placeholder="Libellé"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </div>

          <!-- ERROR MESSAGES -->
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.selectedCadre.$dirty"
          >
            {{ !$v.selectedCadre.required ? "Champ obligatoire" : "" }}
          </span>
          <!--  -->
        </div>
      </template>

      <template #cell(actions)="data">
        <table-actions
          class="justify-content-end"
          :actions="disabled ? [] : ['delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @confirmItem="confirmItem()"
          @cancelItem="cancelItem(data.item)"
          @deleteItem="deleteItem(data.item)"
          @editItem="editItem(data.item)"
        ></table-actions>
      </template>
    </b-table>
    <b-pagination
      aria-controls="table-list"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="right"
    >
    </b-pagination>
  </div>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { uniq } from "lodash";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableActions,
    CustomAddButton,
  },
  props: {
    cadreEntreprises: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      sortBy: "",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedCadre: null,
      tableFields: [
        { key: "type", label: "Type cadre", sortable: true },
        { key: "name", label: "Libellé cadre", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
      tableItems: [],
      ref: "",
      type: "",
      po: null,
      taux: 0,
    };
  },
  validations: {
    selectedCadre: {
      required,
    },
    type: {
      required,
    },
  },
  created() {
    this.$store.dispatch("application/fetchAllApplications");
    this.$store.dispatch("cadre/fetchAllCadres");
  },
  mounted() {
    if (this.applications) this.tableItems = this.applications;
  },
  computed: {
    ...mapGetters("application", ["APPLICATIONS"]),
    ...mapGetters("cadre", ["CADRE_ENTREPRISES"]),
    filteredApplications() {
      return this.APPLICATIONS.filter(
        (application) =>
          !this.applications.find((app) => app.id === application.id)
      );
    },
    cadresTypes() {
      return uniq(
        this.CADRE_ENTREPRISES.filter(
          (cadre) =>
            !this.tableItems.find((item) => item["@id"] === cadre["@id"])
        ).map(({ type }) => type)
      );
    },
    filtredCadres() {
      return this.CADRE_ENTREPRISES.filter(
        (cadre) =>
          (this.type ? cadre.type === this.type : false) &&
          !this.tableItems.find((item) => item["@id"] === cadre["@id"])
      ).map((cadre) => ({
        text: cadre.name,
        value: cadre["@id"],
      }));
    },
    rows() {
      return this.tableItems ? this.tableItems.length : 0;
    },
  },
  methods: {
    addInput() {
      this.tableItems = !this.tableItems.some((item) =>
        item.editMode ? item.editMode : false
      )
        ? [
            {
              editMode: true,
            },
            ...this.tableItems,
          ]
        : this.tableItems;
      if (
        !this.tableItems.some((item) => (item.editMode ? item.editMode : false))
      ) {
        this.createdAt = new Date();
      }
      this.decision = "En attente";
    },
    confirmItem() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        window.console.log(this.tableItems);
        var newCadres = this.tableItems
          .filter((cadre) => cadre["@id"])
          .map((cadre) => cadre["@id"]);
        window.console.log(newCadres);

        newCadres.unshift(this.selectedCadre);
        this.$store
          .dispatch("capaciteSi/updateCapacite", {
            id: this.$route.params.id,
            cadreEntreprises: newCadres,
          })
          .then(() => {
            this.$store
              .dispatch("capaciteSi/fetchCapacite", this.$route.params.id)
              .then(() => {
                Swal.fire("Le cadre est bien créé !", "", "success");
                this.$v.$reset();
                this.type = "";
                this.selectedCadre = null;
              });
          });
      }
    },
    deleteItem(item) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var newCadres = this.tableItems
            .filter((cadre) => cadre.id != item.id)
            .map((cadre) => cadre["@id"]);
          this.$store
            .dispatch("capaciteSi/updateCapacite", {
              id: this.$route.params.id,
              cadreEntreprises: newCadres,
            })
            .then(() => {
              this.$store
                .dispatch("capaciteSi/fetchCapacite", this.$route.params.id)
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                  this.$v.$reset();
                });
            });
        }
      });
    },
    cancelItem() {
      if (this.selectedCadre === null && this.type === "")
        this.$store
          .dispatch("capaciteSi/fetchCapacite", this.$route.params.id)
          .then(() => {
            this.$v.$reset();
          });
      else {
        this.selectedCadre = null;
        this.type = "";
      }
    },
  },
  watch: {
    cadreEntreprises() {
      if (this.cadreEntreprises) this.tableItems = this.cadreEntreprises;
    },
  },
};
</script>
