<template>
  <div>
    <div class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des applications
      </span>
      <div class="d-flex justify-content-end">
        <CustomAddButton
          text="Ajouter une application"
          :disabled="disabled"
          @click="addInput"
        />
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <b-table
      :items="tableItems"
      responsive
      :fields="tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      bordered
      striped
      show-empty
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(taux)="data">
        <b-progress
          v-if="!data.item.hasOwnProperty('editMode')"
          max="100"
          show-progress
          height="15px"
          sstyle="background-color: #cacccd!important;"
        >
          <b-progress-bar
            :style="`background:${
              data.value > 20 && data.value <= 70
                ? '#f5f245'
                : data.value > 70
                ? '#23d92c'
                : '#f5ba45'
            };font-size:8px;`"
            :value="data.value"
            :label="`${data.value}%`"
          ></b-progress-bar>
        </b-progress>
        <b-progress
          v-else
          max="100"
          show-progress
          height="15px"
          sstyle="background-color: #cacccd!important;"
        >
          <b-progress-bar
            :style="`background:${
              taux > 20 && taux <= 70
                ? '#f5f245'
                : taux > 70
                ? '#23d92c'
                : '#f5ba45'
            };font-size:8px;`"
            :value="taux"
            :label="`${taux}%`"
          ></b-progress-bar>
        </b-progress>
      </template>
      <template v-slot:cell(po)="data">
        <TableResponsable
          v-if="!data.item.hasOwnProperty('editMode')"
          :responsable="data.value"
        />
        <v-select placeholder="PO" disabled v-else v-model="po" />
      </template>

      <template v-slot:cell(ref_sa)="data">
        <div>
          <span
            v-if="!data.item.hasOwnProperty('editMode')"
            style="text-decoration: underline; color: blue; cursor: pointer"
          >
            SA{{ data.item.id }}
          </span>
          <v-select v-else disabled placeholder="ref" v-model="ref" />
        </div>
      </template>
      <template v-slot:cell(status)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value || "-"
        }}</span>
        <v-select v-else placeholder="Status" disabled v-model="status" />
      </template>

      <template v-slot:cell(name)="data">
        <b-link
          :to="{
            name: 'show-application',
            params: {
              id: data.item.id,
              slug: data.item.slug,
            },
          }"
          v-if="!data.item.hasOwnProperty('editMode')"
          >{{ data.value }}</b-link
        >
        <div id="add-page" class="row col-12 p-0 m-0" v-else>
          <div
            class="font-weight-normal p-0 form-control"
            style="padding: 0 !important; height: fit-content"
            :class="{
              'is-invalid':
                $v.selectedApplication.$error && $v.selectedApplication.$dirty,
            }"
          >
            <v-select
              :options="filteredApplications"
              appendToBody
              label="name"
              v-model="selectedApplication"
              style="min-width: max-content"
              placeholder="Libellé"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </div>

          <!-- ERROR MESSAGES -->
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.selectedApplication.$dirty"
          >
            {{ !$v.selectedApplication.required ? "Champ obligatoire" : "" }}
          </span>
          <!--  -->
        </div>
      </template>

      <template #cell(actions)="data">
        <table-actions
          class="justify-content-end"
          :actions="disabled ? [] : ['delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @confirmItem="confirmItem()"
          @cancelItem="cancelItem(data.item)"
          @deleteItem="deleteItem(data.item)"
          @editItem="editItem(data.item)"
        ></table-actions>
      </template>
    </b-table>
    <b-pagination
      aria-controls="table-list"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="right"
    >
    </b-pagination>
  </div>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableResponsable from "@/components/custom/TableResponsable";
import TableActions from "@/components/custom/TableActions";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableResponsable,
    TableActions,
    CustomAddButton,
  },
  props: {
    applications: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      sortBy: "",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedApplication: null,
      tableFields: [
        { key: "ref_sa", label: "Ref. SA", sortable: true },
        { key: "name", label: "Libellé", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "po", label: "PO", sortable: true },
        { key: "taux", label: "Taux de remplissage", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
      tableItems: [],
      ref: "",
      status: "",
      po: null,
      taux: 0,
    };
  },
  validations: {
    selectedApplication: {
      required,
    },
  },
  created() {
    this.$store.dispatch("application/fetchAllApplications");
  },
  mounted() {
    this.tableItems = this.applications;
  },
  computed: {
    ...mapGetters("application", ["APPLICATIONS"]),
    filteredApplications() {
      return this.APPLICATIONS.filter(
        (application) =>
          !this.applications.find((app) => app.id === application.id)
      );
    },
    rows() {
      return this.tableItems.length;
    },
  },
  methods: {
    addInput() {
      this.tableItems = !this.tableItems.some((item) =>
        item.editMode ? item.editMode : false
      )
        ? [
            {
              editMode: true,
            },
            ...this.tableItems,
          ]
        : this.tableItems;
      if (
        !this.tableItems.some((item) => (item.editMode ? item.editMode : false))
      ) {
        this.createdAt = new Date();
      }
      this.decision = "En attente";
    },
    confirmItem() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        var newApplications = this.applications.map(
          (application) => application["@id"]
        );
        newApplications.unshift(this.selectedApplication["@id"]);
        this.$store
          .dispatch("capaciteSi/updateCapacite", {
            id: this.$route.params.id,
            applications: newApplications,
          })
          .then(() => {
            this.$store
              .dispatch("capaciteSi/fetchCapacite", this.$route.params.id)
              .then(() => {
                Swal.fire("L'application est bien créée !", "", "success");

                this.selectedApplication = null;
              });
          });
      }
    },
    cancelItem() {
      if (this.selectedApplication) {
        this.selectedApplication = null;
      } else {
        this.$store.dispatch("capaciteSi/fetchCapacite", this.$route.params.id);
      }
    },
    deleteItem(item) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          var newApplications = this.applications
            .filter((application) => application.id != item.id)
            .map((application) => application["@id"]);
          this.$store
            .dispatch("capaciteSi/updateCapacite", {
              id: this.$route.params.id,
              applications: newApplications,
            })
            .then(() => {
              this.$store
                .dispatch("capaciteSi/fetchCapacite", this.$route.params.id)
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },
  },
  watch: {
    applications() {
      this.tableItems = this.applications;
    },
    selectedApplication() {
      if (this.selectedApplication) {
        this.ref = `SA${this.selectedApplication.id}`;
        this.status = this.selectedApplication.status;
        this.po = `${this.selectedApplication.po.firstname} ${this.selectedApplication.po.lastname}`;
        this.taux = this.selectedApplication.taux;
      } else {
        this.ref = "";
        this.status = "";
        this.po = null;
        this.taux = 0;
      }
    },
  },
};
</script>
